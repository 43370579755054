import React, { useState } from 'react';
import { List, ListItem, ListItemText, Checkbox, Button } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemIcon from '@mui/material/ListItemIcon';
import Collapse from '@mui/material/Collapse';
import WaterIcon from '@mui/icons-material/Water';
import formatFloodDate from './FormatDates.js'

import '../styles/DrawerContent.less';

function DrawerContent(props) {

    const [showFloodedArea, setShowFloodedArea] = useState(false);

    const handleShowFloodedArea = () => {
        setShowFloodedArea(true);
    };

    // Function to render FloodedRegions
    const layerListItem = (regionNameDate, floodLayers) => {
        if (Array.isArray(floodLayers)) {
            return floodLayers.map((layerProperties, index) => {
                return (
                    <ListItem key={layerProperties?.resource + '_' + index} dense button={"true"} onClick={() => props.onLayerToggle(regionNameDate, layerProperties)}>
                        <Checkbox
                            edge="start"
                            checked={props.layersData[regionNameDate].satellite_data[index]?.selected ? true : false}
                            tabIndex={-1}
                            disableRipple
                        />
                        <ListItemIcon>
                            <WaterIcon style={{ color: layerProperties?.plotColor }} />
                        </ListItemIcon>
                        <ListItemText primary={formatFloodDate(layerProperties?.datetimeUTC)} />
                    </ListItem>
                )
            })
        }
        return null;
    };

    // Function to render svg with flood area over time
    const renderFloodedareaSVG = (name) => {
        return (
            <div>
                <Button 
                    variant="outlined"  // This makes the button outlined
                    color="inherit"     // Use inherit to avoid any theme color conflicts
                    sx={{
                        backgroundColor: 'white',    // Set the background to white for contrast with the outline
                        borderColor: 'gray',         // Set the border color to gray
                        borderWidth: 1,              // Set the border width
                        color: 'gray',               // Set the text color to gray
                        fontSize: '0.875rem',        // Make the text a bit smaller
                        padding: '6px 12px',         // Adjust padding to make the button smaller
                        width: '80%',                // Set the button width to 80% of the drawer width
                        margin: '0 auto',            // Center the button horizontally
                        display: 'block',            // Ensure the button is treated as a block element
                        '&:hover': {
                          backgroundColor: 'lightgray',  // Optional: change background color on hover
                          borderColor: 'gray',           // Keep the border gray on hover
                        },
                      }}
                    onClick={handleShowFloodedArea}>
                    {showFloodedArea ? 'Hide Flooded Area over time' : 'Show Flooded Area over time'}
                </Button>

                <Dialog open={showFloodedArea} onClose={() => setShowFloodedArea(false)} aria-labelledby="disclaimer-dialog-title">
                    <DialogTitle id="disclaimer-dialog-title"><div><h2>{name} </h2></div></DialogTitle>
                    <DialogContent>
                        <img src={`../data/${name}.svg`} alt="Area (km²) of flooded regions" style={{ width: '100%', maxWidth: 360 }} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setShowFloodedArea(false)} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    };

    const renderRegionLayersList = () => {
        if (typeof props.layersData === 'object' && Object.keys(props.layersData).length > 0) {
            return Object.keys(props.layersData).map(regionNameDate => {
                const folderObject = props.layersData?.[regionNameDate];
                if (folderObject?.visible) {
                    return (
                        <div key={'list_item_button_container_' + regionNameDate}>
                            {layerListItem(regionNameDate, folderObject?.satellite_data)}
                            {renderFloodedareaSVG(regionNameDate)}
                        </div>
                    );
                }
            })
        }
        return null;
    };

    return (
        <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                    Flood Events
                </ListSubheader>
            }
        >
            {renderRegionLayersList()}
        </List>
    );
}
export default DrawerContent;