import React, { useRef, useState, useEffect } from 'react';
import { MapContainer, TileLayer, WMSTileLayer, GeoJSON, Polygon, Marker, Popup, useMapEvents, useMap } from 'react-leaflet';
import { Box } from '@mui/material';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import '../styles/MapView.less';
import '../styles/MapStyles.css';
import FloodIcon from "../styles/logo.png"; // Replace with the path to your icon
import MarkerClusterGroup from 'react-leaflet-cluster';  // Import the new cluster package
import LinearProgress from '@mui/material/LinearProgress';

delete L.Icon.Default.prototype._getIconUrl;
// overrides marker icons of leaflet.css
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

// const onEachFloodExtend = (feature, layer) => {
//     const AcquisitionDate = 'Flooded regions at: 6-Sep-2023 04:40 UTC';
//     layer.on('click', function (e) {
//         layer.bindPopup(AcquisitionDate).openPopup();
//     });
// };

const zoomLevelThres = 9
const MapView = (props) => {
    // const [center, setCenter] = useState();
    const [zoomLevel, setZoomLevel] = useState(2);
    const [selectedRegionBbox, setSelectedRegionBbox] = useState(null);
    // This flag will be used to track whether the user has interacted with the map
    const [userInteracted, setUserInteracted] = useState(false);

    const handleIconClick = (bbox) => {
        setSelectedRegionBbox(bbox);
        setUserInteracted(false); // Reset interaction flag when a marker is clicked
        
    };

    // This function clears the selected bbox after zooming to avoid repeated flyToBounds
    const clearSelectedBbox = () => {
        setSelectedRegionBbox(null);
    };

    const handleMapInteraction = () => {
        setUserInteracted(true); // Set flag to true when the user interacts with the map
    };

    const waterIcon = L.icon({
        iconUrl: FloodIcon,  // Path to the custom icon
        iconSize: [32, 32],      // Size of the icon
        iconAnchor: [16, 32],    // Anchor point of the icon (centered at the bottom)
        popupAnchor: [0, -32],    // Position of the popup relative to the icon
        shadowSize: [41, 41] // Size of the shadow
    });

    const ZoomToRegion = ({ bbox, clearBbox }) => {
        const map = useMap()
    
        useEffect(() => {
            if (bbox) {
                map.fitBounds([
                    [bbox[1], bbox[0]],  // Southwest coordinates
                    [bbox[3], bbox[2]]   // Northeast coordinates
                ], { animate: true });
    
            // Manually trigger GetMapCenter logic after fitBounds
            const center = map.getCenter();
            const zoom = map.getZoom();
            triggerGetMapCenter(center, zoom);  // Call a function to handle the map center logic


                // Clear the bbox after zooming to prevent further flyToBounds behavior
                clearBbox();
            }
        }, [bbox, map, clearBbox]);
    
        return null;
    };


    // Track the map zoom level
    const MapEvents = () => {
        const map = useMapEvents({
            zoomend: () => {
                const newZoomLevel = map.getZoom();  // Get the updated zoom level
                setZoomLevel(newZoomLevel);  // Update zoomLevel state
            },
    });
        return null;
    };

    // Helper function to check if the map center is within the bounding box
    const triggerGetMapCenter = (center, zoomLevel) => {
        if (typeof props.layersData === 'object' && Object.keys(props.layersData).length > 0 && (zoomLevel >= zoomLevelThres)) {
            for (const floodedAreaName in props.layersData) {
                const floodedArea = props.layersData[floodedAreaName];
                const [minLng, minLat, maxLng, maxLat] = floodedArea?.bbox;
                const floodedAreaBbox = L.latLngBounds(
                    L.latLng(minLat, minLng),  // Southwest corner
                    L.latLng(maxLat, maxLng)   // Northeast corner
                );
                if (floodedAreaBbox && floodedAreaBbox.contains(center)) {
                    props.handleRegionsVisibility(floodedAreaName);
                } 
            }
        }
    };

    const GetMapCenter = () => {
        const map = useMap();
        useEffect(() => {
            const handleMove = () => {
                const center = map.getCenter();
                const zoomLevel = map.getZoom();
                triggerGetMapCenter(center, zoomLevel);

                };

            map.on("move", handleMove);
            map.on("moveend", handleMove);
            map.on("zoomend", handleMove);

            return () => {
                map.off("move", handleMove);
                map.off("moveend", handleMove);
                map.off("zoomend", handleMove);
            };
        }, [map, zoomLevel]);

        return null;
    };

    // Function to convert bbox to polygon coordinates
    const drawBBoxAsPolygon = (bbox) => {
        const [minLng, minLat, maxLng, maxLat] = bbox;
        return [
            [minLat, minLng],
            [minLat, maxLng],
            [maxLat, maxLng],
            [maxLat, minLng],
        ];
    };

    // Function to convert bbox to point coordinates (centroid)
    const drawBBoxAsPoint = (bbox) => {
        const [minLng, minLat, maxLng, maxLat] = bbox;
        const bboxCenter = L.latLngBounds([
            [minLat, minLng],
            [maxLat, maxLng]
        ]).getCenter();
        return bboxCenter;
    };

    // Function to render uniqueFloodRegions as markers or polygons based on zoom level
    const renderUniqueFloodRegions = () => {
        if (zoomLevel <= zoomLevelThres) {
            // Return the markers if zoom level is less than or equal to the threshold
            return Object.entries(props.layersData).map(([key, region], index) => (
                <Marker
                    key={index}
                    position={drawBBoxAsPoint(region.bbox)}
                    icon={waterIcon}
                    eventHandlers={{
                        click: () => handleIconClick(region.bbox),
                    }}
                >
                    <Popup>
                        <div>
                            <strong>Zooming to {region.name}</strong><br />
                        </div>
                    </Popup>
                </Marker>
            ));
        } else {
            // Return the polygons if zoom level is greater than the threshold
            return Object.entries(props.layersData).map(([key, region], index) => (
                <Polygon
                    key={index}
                    positions={drawBBoxAsPolygon(region.bbox)}
                    pathOptions={{
                        color: '#000000',
                        weight: 2,
                        opacity: 0.65,
                        fillColor: '#000000',
                        fillOpacity: 0.0,
                    }}
                >
                    <Popup>
                        <div>
                            <strong>Flood event:</strong> {region.name}<br />
                        </div>
                    </Popup>
                </Polygon>
            ));
        }
    };

    const renderMarkers = () => {
        if (zoomLevel <= zoomLevelThres) {
            return <MarkerClusterGroup>
                        {renderUniqueFloodRegions()}
                    </MarkerClusterGroup>
        } else {
            return renderUniqueFloodRegions()
        }
    }

    const renderVisibleRegionsLayers = () => {
        if (typeof props.layersData === 'object' && Object.keys(props.layersData).length > 0) {
            const layersToRender = [];
            for (const regionNameDate in props.layersData) {
                const region = props.layersData[regionNameDate];
                if (region?.visible && Array.isArray(region?.satellite_data)) {
                    for (let index = 0; index < region.satellite_data.length; index++) {
                        const layer = region.satellite_data[index];
                        if (layer?.selected && props.availableLayers?.[layer.resource]) {
                            layersToRender.push(<GeoJSON
                                key={layer?.resource}
                                data={props.availableLayers[layer.resource]}
                                style={() => ({
                                    color: layer?.plotColor,
                                    weight: 2,
                                    opacity: 0.65
                                })}
                            />)
                        }
                    }
                }
            }
            return layersToRender;
        }
        return null;
    };

    return (
        <Box>
            <MapContainer center={[45, 13]}
             zoom={zoomLevel}
             whenCreated={map => {
                // Add event listeners for user interactions (move/zoom)
                map.on('zoomend', handleMapInteraction);
                map.on('moveend', handleMapInteraction);
            }}
             className="map-container" >
                {props.selectedBackground === 'osm' ? (
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Tiles courtesy of Humanitarian OSM Team'
                    />
                    // I can also add WMS layers
                    // <WMSTileLayer
                    //   url="https://copernicus.discomap.eea.europa.eu/arcgis/services/CLC_plus/CLMS_CLCplus_RASTER_2021_010m_eu/ImageServer/WMSServer"
                    //   layers="CLMS_CLCplus_RASTER_2021_010m_eu"
                    //   format="image/png"
                    //   transparent={true}
                    //   version="1.3.0"
                    //   attribution="&copy; Copernicus"
                    // />
                ) : (
                    <WMSTileLayer
                        layers="Google"
                        url="http://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}"
                        attribution="&copy; Google Satellite Imagery"
                    />
                )}
                {renderVisibleRegionsLayers()}
                <MapEvents />
                <GetMapCenter />
                {
                    props.layerLoading && <div className="progress-bar-container">
                        <LinearProgress className="progress-bar"/>
                    </div>
                }

                {renderMarkers()}
                {/* Trigger zoom to the selected region's bbox */}
                {!userInteracted && selectedRegionBbox && (
                    <ZoomToRegion bbox={selectedRegionBbox} clearBbox={clearSelectedBbox} />
                )}
            </MapContainer>
        </Box>
    );
};

export default MapView;